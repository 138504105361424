import React from "react";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Center,
} from "@chakra-ui/react";
import { useGameComplete } from "../../../redux/hooks/useGameComplete";
import { useNavigate } from "react-router-dom";
import { getCurrentGameScore } from "./helper";

export const EndGameModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const navigate = useNavigate();
  const { gameComplete } = useGameComplete(getCurrentGameScore());

  const onSubmit = async () => {
    try {
      await gameComplete();
      navigate("/spelling-result");
      onClose();
    } catch (error: any) {
      console.log(error.message);
    }
  };
  return (
    <>
      <Button size="sm" colorScheme="blue" variant="outline" onClick={onOpen}>
        End Game
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent p={3}>
          <ModalHeader>End Game</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>Are you sure you want to end this game?</ModalBody>
          <ModalFooter>
            <Center>
              <Button colorScheme={"blue"} mr={3} onClick={onSubmit}>
                Yes
              </Button>
              <Button variant="outline" colorScheme={"blue"} onClick={onClose}>
                No
              </Button>
            </Center>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
