import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Heading,
    VStack,
  } from "@chakra-ui/react";
  
  export const FAQs = () => {
    return (
      <VStack py={10} px={5} spacing={5}>
        <Heading textAlign="center" mb={5}>
          Frequently Asked Questions
        </Heading>
        <Accordion allowToggle>
          {/* Question 1 */}
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  What is the purpose of TheLastSpellingBee app?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              TheLastSpellingBee app is designed to improve memory and retention skills, helping users prepare for spelling bees or enhance their language abilities with interactive and personalized learning features.
            </AccordionPanel>
          </AccordionItem>
  
          {/* Question 2 */}
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  How does TheLastSpellingBee app help improve memory?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              The app uses engaging, interactive exercises and personalized feedback to train your brain, making it easier to remember and retain information effectively over time.
            </AccordionPanel>
          </AccordionItem>
  
          {/* Question 3 */}
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Who can benefit from using TheLastSpellingBee app?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              The app is suitable for anyone looking to enhance their memory and language skills, including students preparing for spelling bees and professionals aiming to improve their cognitive abilities.
            </AccordionPanel>
          </AccordionItem>
  
          {/* Question 4 */}
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  What is TheLastSpellingBee API?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              TheLastSpellingBee API is a tool to prevent spam and abuse by distinguishing between humans and bots on websites. It integrates a Re-CAPTCHA solution to ensure security and block automated programs.
            </AccordionPanel>
          </AccordionItem>
  
          {/* Question 5 */}
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  How does TheLastSpellingBee API prevent spam and abuse?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              The API uses advanced algorithms to differentiate legitimate users from bots. It presents challenges that are simple for humans to solve but difficult for automated programs, effectively preventing malicious activities.
            </AccordionPanel>
          </AccordionItem>
  
          {/* Question 6 */}
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  Is the app suitable for all age groups?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Yes, TheLastSpellingBee app is designed for all age groups. Its intuitive interface and personalized features make it accessible and beneficial for kids, teens, and adults alike.
            </AccordionPanel>
          </AccordionItem>
  
          {/* Question 7 */}
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  How can I integrate TheLastSpellingBee API into my website?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Integrating the API is simple and straightforward. Detailed documentation is provided to guide developers through the process of adding TheLastSpellingBee's Re-CAPTCHA to their websites.
            </AccordionPanel>
          </AccordionItem>
  
          {/* Question 8 */}
          {/* <AccordionItem>
            <h2>
              <AccordionButton>
                <Box flex="1" textAlign="left">
                  How do I get started with TheLastSpellingBee app?
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              Getting started is easy! Simply download the app, create an account, and follow the onboarding process to begin improving your memory and language skills today.
            </AccordionPanel>
          </AccordionItem> */}
        </Accordion>
      </VStack>
    );
  };
  