import { Flex, Icon, Link, Text, Box, useToast } from "@chakra-ui/react";
import {
  FaCopy,
  FaEnvelope,
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { MotionElement } from "../comon/MotionElement";
import { FC } from "react";
import { getInviteLink } from "../spelling/game-play/helper";

interface ShareLinksProps {
  id: number;
  handle: string;
}

export const ShareLinks: FC<ShareLinksProps> = ({ id, handle }) => {
  const inviteId = `${id}-${handle}`;
  const inviteLink = getInviteLink(inviteId);
  const toast = useToast();

  return (
    <Flex align="center">
      <Text
        fontSize={{ base: 14, sm: 17 }}
        color={"gray.700"}
        fontWeight="bold"
        me="10px"
      >
        Share:{" "}
      </Text>
      <MotionElement type="fadeInY">
        <Box>
          <Link
            href={`https://www.facebook.com/sharer.php?u=${inviteLink}`}
            target="_blank"
            color="#2b6cb0"
            fontSize={{ base: 14, sm: 17 }}
            me="10px"
          >
            <Icon as={FaFacebook} />
          </Link>
          <Link
            href={`mailto:?body=${inviteLink}`}
            target="_blank"
            color="#2b6cb0"
            fontSize={{ base: 14, sm: 17 }}
            me="10px"
          >
            <Icon as={FaEnvelope} />
          </Link>
          <Link
            href={`https://twitter.com/intent/tweet?url=${inviteLink}`}
            target="_blank"
            color="#2b6cb0"
            fontSize={{ base: 14, sm: 17 }}
            me="10px"
          >
            <Icon as={FaTwitter} />
          </Link>
          <Link
            href={`https://www.linkedin.com/shareArticle?url=${inviteLink}`}
            target="_blank"
            color="#2b6cb0"
            fontSize={{ base: 14, sm: 17 }}
            me="10px"
          >
            <Icon as={FaLinkedin} />
          </Link>
          <Link
            href={`https://www.instagram.com`}
            target="_blank"
            color="#2b6cb0"
            fontSize={{ base: 14, sm: 17 }}
            me="10px"
          >
            <Icon as={FaInstagram} />
          </Link>
          <Link
            href="#"
            color="#2b6cb0"
            fontSize={{ base: 14, sm: 17 }}
            me="10px"
            onMouseDown={() =>
              toast({
                title: "Link copied..",
                description: "We've copied the game url",
                status: "success",
                position: "top-right",
                duration: 9000,
                isClosable: true,
              })
            }
            onClick={() => navigator.clipboard.writeText(inviteLink)}
          >
            <Icon as={FaCopy} />{" "}
            <Text as={"span"} fontSize={{ base: 14, sm: 17 }}>
              Copy link
            </Text>
          </Link>
        </Box>
      </MotionElement>
    </Flex>
  );
};
