import { Box, Heading, Text, Img, Button, Link } from "@chakra-ui/react";
import { RecaptchaHero } from "./RecaptchaHero";
import { MotionElement } from "../comon/MotionElement";
import ContentCard from "../nav/ContentCard";
import { ApiReferenceMenu } from "../nav/ApiReferenceMenu";

export const AboutRecaptcha = () => {
  return (
    <ContentCard>
      <ApiReferenceMenu />
      <Box>
        <MotionElement type="fadeInX">
          <Heading mb={4} textAlign="center">
            What is Re-captcha?
          </Heading>
        </MotionElement>

        <Box
          mt={10}
          fontSize={{ base: "16px", md: "18px" }}
          rounded="lg"
          bg="#FFF"
          boxShadow="lg"
          p={6}
        >
          <Text mb={4}>
            ThelastSpellingBee Re-captcha is a free tool that helps to prevent
            spam and abuse on your website. It uses advanced techniques to
            differentiate between humans and bots.
          </Text>

          <Box padding={14}>
            <MotionElement type="fadeInX">
              <Img src="images/advert.gif" mb={6} />
            </MotionElement>
          </Box>

          <Text mb={4}>
            Fraud on the internet is a major problem that costs businesses
            billions of dollars annually. To protect their websites and provide
            secure access to their customers, security teams must prevent
            hackers from gaining access and ensure that their website is always
            secure.
          </Text>

          <Text mb={4}>
            Re-captcha has a long history of defending the internet and data for
            its network of more than 5 million sites.ThelastSpellingBee
            Re-captcha service offers additional security features, such as
            usingThelastSpellingBee A1-B2-C3-D4 IQ questions, specifically
            designed for enterprise security needs.
          </Text>

          <Text mb={4}>
            WithThelastSpellingBee Re-captcha, businesses can defend their
            website against common web-based attacks, such as credential
            stuffing, account takeovers, and scraping, helping to prevent costly
            exploits from both human and automated attackers.
            Additionally,ThelastSpellingBee Re-captcha will not interrupt users
            with challenges, allowing it to be implemented on all webpages where
            customers interact with the business's services.
          </Text>

          <Box textAlign="center" mt={6} mb={8}>
            <MotionElement type="fadeInY">
              <Link href="/api-reference">
                <Button colorScheme="blue" variant="outline">
                  Get started
                </Button>
              </Link>
            </MotionElement>
          </Box>
          <MotionElement type="fadeInX">
            <RecaptchaHero />
          </MotionElement>
        </Box>
      </Box>
    </ContentCard>
  );
};
