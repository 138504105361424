import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ISignUp, RecoverPassword, ResetPassword } from '../../../apps/auth/schema';
import authService from '../../service/auth/authService';
import { googleLogout } from '@react-oauth/google';
import { clearStorage } from '../../../apps/spelling/game-play/helper';

type SignUpState = {
  data: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: SignUpState = {
  data: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
};

export const signUpAction = createAsyncThunk('auth/signUp', async (data: ISignUp, thunkAPI) => {
  try {
    return await authService.signUp(data);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const signInAction = createAsyncThunk('auth/signIn', async (data: ISignUp, thunkAPI) => {
  try {
    return await authService.signIn(data);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const verifyEmailAction = createAsyncThunk('auth/verifyEmail', async (token: string, thunkAPI) => {
  try {
    return await authService.verifyEmail(token);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateAccountAction = createAsyncThunk('auth/updateAccount', async (data: ISignUp, thunkAPI) => {
  try {
    return await authService.updateAccount(data,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const recoverPasswordAction = createAsyncThunk('auth/recoverPassword', async (email: RecoverPassword, thunkAPI) => {
  try {
    return await authService.recoverPassword(email);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});
export const resetPasswordAction = createAsyncThunk('auth/resetPassword', async (payload: ResetPassword, thunkAPI) => {
  try {
    return await authService.resetPassword(payload);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const signOutAction = createAsyncThunk('auth/signOut', async (_, thunkAPI: any) => {
  try {
    thunkAPI.dispatch(authSlice.actions.authLogout());
    googleLogout();
    clearStorage();
    console.log("thunkAPI: ",thunkAPI.getState().auth.data.company);
    window.location.href = '/';
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const handleRejected = (state: SignUpState, action: any) => {
  state.isLoading = false;
  state.isSuccess = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};
const handleFulfilled = (state: SignUpState, action: any) => {
  if(action?.payload?.company?.status === 500 || action?.payload?.company?.status === 511){
      state.isLoading = false
      state.isSuccess = false
      state.isError = true
      state.errorMessage = action.payload.company.errorMessage
      state.data = null
  }else{
      state.isLoading = false
      state.isSuccess = true
      state.isError = false
      state.data = action?.payload?.company?.user ?  action.payload as ISignUp : null;
  }
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.errorMessage = ''
    },
    authLogout: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUpAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signUpAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(signUpAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(verifyEmailAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyEmailAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(verifyEmailAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(signInAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signInAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(signInAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(updateAccountAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAccountAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(updateAccountAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(recoverPasswordAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(recoverPasswordAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(recoverPasswordAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(resetPasswordAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPasswordAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(resetPasswordAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});



export const { resetAuth } = authSlice.actions;
export const { authLogout } = authSlice.actions;
export default authSlice.reducer;