import React, { useEffect, useState } from "react";
import {
  Box,
  Select,
  SelectProps,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import { UseFormRegister, Controller } from "react-hook-form";

interface Option {
  value: string;
  label: string;
}

interface CustomSelectProps extends Omit<SelectProps, "defaultValue"> {
  selectType?: "dropDown" | "slider";
  options: Option[];
  defaultValue?: string | number;
  register: UseFormRegister<any>;
  id: string;
  control?: any;
  placeholder: string;
}

export const CustomSelect: React.FC<CustomSelectProps> = ({
  register,
  id,
  control,
  placeholder,
  selectType = "dropDown",
  defaultValue = "",
  options,
  ...rest
}) => {
  const [sliderValue, setSliderValue] = useState<number>(2);
  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };

  useEffect(() => {
    setSliderValue(control?.field?.value ?? 2);
  }, [control?.field?.value]);

  return (
    <Box>
      {selectType === "dropDown" ? (
        <Select {...rest} id={id} defaultValue={defaultValue} {...register(id)}>
          <option value="" disabled>
            {placeholder}
          </option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      ) : (
        <Controller
          render={({ field }) => (
            <>
              <Slider
                {...field}
                colorScheme="blue"
                onChange={(val) => {
                  setSliderValue(val);
                  field.onChange(val);
                }}
                defaultValue={3}
              >
                <SliderMark value={25} {...labelStyles}>
                  {/* 25 */}
                </SliderMark>
                <SliderMark value={50} {...labelStyles}>
                  {/* 50 */}
                </SliderMark>
                <SliderMark value={75} {...labelStyles}>
                  {/* 75 */}
                </SliderMark>
                <SliderMark value={100} {...labelStyles}>
                  100
                </SliderMark>
                <SliderMark
                  value={sliderValue}
                  textAlign="center"
                  bg="teal"
                  color="white"
                  mt="-10"
                  ml="-5"
                  w="9"
                >
                  {sliderValue}
                </SliderMark>
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </>
          )}
          control={control}
          name={id}
        />
      )}
    </Box>
  );
};
