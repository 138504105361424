import { Flex, Icon, Link, Text, useColorModeValue } from "@chakra-ui/react";
import { FiHome, FiFile, FiRadio, FiPackage } from "react-icons/fi";
import { Link as ReactRouterLink } from "react-router-dom";

export const ApiReferenceMenu = () => {
  const bg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const navItems = [
    { title: "About", to: "/about-recaptcha", Icon: FiHome },
    { title: "Api Reference", to: "/api-reference", Icon: FiFile },
    { title: "NPM Package", to: "/npm/the-last-spelling-bee-re-captcha", Icon: FiPackage },
    { title: "Get Api Key", to: "/api-key", Icon: FiRadio },
  ];

  return (
    <Flex
      as="nav"
      position="sticky"
      top={0}
      zIndex={1000}
      bg={bg}
      borderBottom="1px solid"
      borderColor={borderColor}
      p={4}
      justify="center"
      align="center"
      width="100%"
      boxShadow="sm"
      borderRadius={"md"}
      mt={-10}
      mb={10}
    >
      <Flex
        maxW="1200px"
        width="100%"
        justify={{ base: "space-between", md: "center" }}
        align="center"
        flexWrap="wrap"
        gap={4}
      >
        {navItems.map((item) => (
          <Flex key={item.to}  alignItems="center">
            <Link
              as={ReactRouterLink}
              to={item.to}
              ml={5}
              display={"block"}
              width={"100%"}
              p={2}
              borderRadius={3}
              _hover={{ color: "#2b6cb0", bg: "#edf2f7" }}
            >
              <Icon as={item.Icon} />
              <Text as={"span"} ml={1}>
                {item.title}
              </Text>
            </Link>
          </Flex>
        ))}
       
      </Flex>
    </Flex>
  );
};
