import { Box, useColorModeValue } from "@chakra-ui/react";
import { Hero } from "./hero";
import { AboutSection } from "./about-section";
import { FAQs } from "./FAQs";
import { LatestResults } from "./LatestResults";

export const Home = () => {
  return (
    <Box>
      {/* Hero Section */}
      <Hero />
      <AboutSection />

      {/* FAQ Section */}
      <FAQs />

      {/* Latest Results Section */}
      <Box
        // py={{ base: 0, md: 0, lg: 0, xl: 10, "2xl": 10 }}
        // p={{ base: 0, md: 0, lg: 0, xl: 8, "2xl": 8 }}
        bg={useColorModeValue("blue.50", "gray.800")}
      >
        <LatestResults />
      </Box>
    </Box>
  );
};
