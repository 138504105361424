import { FC } from "react";
import { Stack, SimpleGrid, Button, Box, Text } from "@chakra-ui/react";
import { GamePlayInstruction } from "./GamePlayInstruction";
import { GameTime } from "./GameTime";

interface GameHeaderCardProps {
  currentQuestionIndex: number;
  totalQuestions: number;
  score?: number;
  gameTime: number;
  gameName?: string;
  onGameOver?: (gameCompleteTime?: string) => void;
}

export const GameHeaderCard: FC<GameHeaderCardProps> = ({
  currentQuestionIndex,
  totalQuestions,
  score,
  gameTime,
  gameName,
  onGameOver,
}) => {
  const onTimerEnd = (gameCompleteTime: string) => {
    if (onGameOver) {
      onGameOver(gameCompleteTime);
    }
  };
  if (!localStorage.getItem("iendmygame")) {
    localStorage.setItem("iendmygame", btoa("0"));
  }
  const gameScoreString = atob(localStorage.getItem("iendmygame") ?? "0");
  const gameScore = parseInt(gameScoreString ?? "0");

  return (
    <Stack p="4" m="4" w={"full"} borderRadius="sm" >
      <Text
        fontSize={"xl"}
        fontWeight={600}
        color={"blue.700"}
        textTransform={"capitalize"}
        ml={3}
      >
        {gameName}
      </Text>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
        <Box alignItems="center">
          <GamePlayInstruction />
        </Box>
        <Box alignItems="center">
          <Text fontWeight={600} color={"blue.500"} fontSize={"lg"}>
            {" "}
            Question {currentQuestionIndex + 1} of {totalQuestions}
          </Text>
        </Box>
        <Box textAlign="right">
          <Button
            size="lg"
            cursor={"default"}
            colorScheme="blue"
            variant="outline"
            mr={9}
          >
            Score: {gameScore > 0 ? gameScore : 0}
          </Button>
          <GameTime
            initialMinuetsBySeconds={gameTime * 60}
            onTimerEnd={onTimerEnd}
          />
        </Box>
      </SimpleGrid>
    </Stack>
  );
};
