import React, { FC, useState } from "react";
import {
  Box,
  Button,
  Flex,
  Heading,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
} from "recharts";
import { GameResult } from "../../../../redux/service/schema";
import { PlayerDetailsModal, PlayerResultProps } from "./PlayerDetailsModal";

interface TeamBondingResultProps {
  result: GameResult[];
  isBackBtn?: boolean;
  isAuthView?: boolean;
}

export const TeamBondingResult: FC<TeamBondingResultProps> = ({
  result,
  isBackBtn =false,
  isAuthView = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerResultProps>();

  const handleChartClick = (event: any) => {
    if (event.activePayload && event.activePayload[0]) {
      const player = event.activePayload[0].payload;
      setSelectedPlayer(player as PlayerResultProps);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const data = result.map((player) => {
    const totalMarks = parseInt(player.numberOfQuestions) * 5;
    const percentage = ((player.score / (totalMarks * 5)) * 100).toFixed(2);
    const correctAnswers = player.score / 5;
    const incorrectAnswers =
      parseInt(player.numberOfQuestions) - correctAnswers;

    return {
      name: player.userName,
      value: percentage,
      correct: correctAnswers.toFixed(0),
      incorrect: incorrectAnswers.toFixed(0),
      result: player,
    };
  });

  return (
    <Flex minH={"100vh"} align={"center"} width={"full"} justify={"center"}>
      <Stack
        spacing={8}
        mx={"auto"}
        py={12}
        px={6}
        width={{ base: "full", md: 990 }}
        bg={"#FFF"}
      >
        <Box
          textAlign="center"
          p={6}
          boxShadow="lg"
          borderRadius="md"
          paddingRight={1}
        >
          {isBackBtn ? (
            <Box textAlign="left">
              <Link
                href={`/game-result/${result[0].gameId}`}
                color="#2b6cb0"
                fontSize={{ base: 14, sm: 17 }}
                me="10px"
              >
                <Button h={8} colorScheme="blue" variant="outline">
                  Back
                </Button>
              </Link>
            </Box>
          ) : (
            ""
          )}
          <Heading
            mb={4}
            fontSize={20}
            color={"rgb(242, 107, 58)"}
            textTransform={"capitalize"}
          >
           ThelastSpellingBee - Team Bonding Result
          </Heading>
          <Heading mb={4} fontSize={17} textTransform={"capitalize"}>
            Team: {`${result[0].gameName}`}
          </Heading>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={data}
              layout="vertical"
              barCategoryGap={1}
              margin={{ top: 0, right: 43, left: 0, bottom: 0 }}
              onClick={handleChartClick}
            >
              <CartesianGrid strokeDasharray="0 3" />
              <XAxis type="number" hide />
              <YAxis type="category" width={100} dataKey="name" />
              <Bar
                dataKey="value"
                fill="#2f855a"
                radius={[0, 50, 50, 0]}
                barSize={11}
                label={{
                  position: "right",
                  fontSize: "14px",
                  fontWeight: "bold",
                  color: "#F00",
                }}
                cursor={"pointer"}
              />
            </BarChart>
          </ResponsiveContainer>
          <Box textAlign="left" p={6} borderRadius="md">
            {/* Add other details like Date, Time taken, Invited by here */}
          </Box>
          <Text color={"#F26B3A"} fontWeight={"bold"}>
            {!isAuthView ? "Awesome teamwork! Your team did great." : "" }
          </Text>
          <Text fontWeight={"bold"}>
            Click any of the bar to view the full details
          </Text>
        </Box>
      </Stack>
      <PlayerDetailsModal
        isOpen={isModalOpen}
        onClose={closeModal}
        player={selectedPlayer}
      />
    </Flex>
  );
};
