import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  useColorModeValue,
  useDisclosure,
  ChakraProvider,
  Avatar,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import { EndGameModal } from "../spelling/game-play/EndGameModal";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux/hooks/useTypedSelector";
import { GameData } from "../../redux/slice/game/schema";
import { Sidebar } from "./Sidebar";
import { Company } from "../../redux/slice/auth/schema";
import { useState } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { signOutAction } from "../../redux/slice/auth/authSlice";

export const DefaultNav = (props: { [x: string]: any }) => {
  const { playGame } = useAppSelector((state) => state.gamePlay);
  const { data } = useAppSelector((state) => state.auth);
  const gameData = playGame as GameData;
  const { isOpen, onToggle } = useDisclosure();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const dispatch = useAppDispatch();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const logout = (e: { preventDefault: () => void }) => {
    dispatch(signOutAction());
  };

  return (
    <ChakraProvider>
      <Box>
        <Flex
          bg={useColorModeValue("white", "gray.800")}
          color={useColorModeValue("gray.600", "white")}
          minH={"60px"}
          py={{ base: 2 }}
          px={{ base: 4 }}
          borderBottom={1}
          borderColor={useColorModeValue("gray.200", "gray.900")}
          backgroundColor="white"
          shadow={2}
          align={"center"}
          as="header"
          position="fixed"
          w="100%"
          zIndex={1000}
        >
          <Flex
            flex={{ base: 1, md: "auto" }}
            ml={{ base: -2 }}
            display={{
              base: "flex",
              md: "flex",
              lg: "flex",
              xl: "none",
              "2xl": "none",
            }}
          >
            <IconButton
              onClick={onToggle}
              icon={
                isOpen ? (
                  <CloseIcon w={3} h={3} />
                ) : (
                  <HamburgerIcon w={5} h={5} />
                )
              }
              variant={"ghost"}
              aria-label={"Toggle Navigation"}
            />
          </Flex>
          <Flex
            flex={{ base: 1 }}
            justify={{ base: "center", md: "start" }}
            pb={data?.company ? 7 : 0}
          >
            <Text
              as={"a"}
              href={"/"}
              fontSize={{ base: 18, md: 22 }}
              fontWeight={400}
              color={"#000"}
              ml={{ base: 0, sm: 1 }}
            >
              TheLast
              <Text
                as={"span"}
                color={"#F26B3A"}
                borderTop={"2px #F26B3A solid"}
                borderBottom={"2px #F26B3A solid"}
                borderRadius={7}
                p={"0 3px"}
              >
                Spelling
              </Text>
              Bee
            </Text>
            <Flex display={{ base: "none", md: "flex" }} ml={600}>
              {!data?.company && !gameData?.user?.user[0]?.userName ? (
                <DesktopNav />
              ) : (
                ""
              )}
            </Flex>
          </Flex>
          <Stack
            flex={{ base: 1, md: 0 }}
            justify={"flex-end"}
            direction={"row"}
            spacing={6}
            paddingRight={90}
          >
            {gameData?.user?.user[0]?.userName ? (
              <>
                <Button size="sm" colorScheme="blue" variant="ghost">
                  Hi {gameData?.user?.user[0]?.userName}
                </Button>
                <EndGameModal />
              </>
            ) : (
              <>
                {data?.company ? (
                  <Flex mt={4} align="center" w={110} ml={{ base: 4, md: 0 }}>
                    <Menu>
                      <MenuButton
                        onClick={toggleMenu}
                        as={Box}
                        cursor="pointer"
                      >
                        <Text>
                          <Avatar size="sm" mb={-1} src="avatar-1.jpg" />
                          <Text
                            as={"span"}
                            fontSize={{ base: 12, md: 12 }}
                            ml={1}
                          >
                            {data.company.user?.company_name.split(" ")[0]}
                          </Text>
                        </Text>
                      </MenuButton>
                      <MenuList minW="120px">
                        <MenuItem onClick={logout}>
                          <Icon as={FaSignOutAlt} />
                          Sign out
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </Flex>
                ) : (
                  <Box display={{ base: "none", md: "block" }}>
                    <Flex>
                      <Button
                        as={"a"}
                        href={"/sign-in"}
                        colorScheme="blue"
                        variant="ghost"
                        cursor={"pointer"}
                        fontSize={"1.1rem"}
                        fontWeight={700}
                      >
                        Sign In
                      </Button>
                      <Button
                        as={"a"}
                        href={"/sign-up"}
                        colorScheme="blue"
                        variant="ghost"
                        cursor={"pointer"}
                        fontSize={"1.1rem"}
                        fontWeight={700}
                      >
                        Sign Up
                      </Button>
                    </Flex>
                  </Box>
                )}
              </>
            )}
          </Stack>
        </Flex>
        {!data?.company && (
          <Collapse in={isOpen} animateOpacity>
            <MobileNav />
          </Collapse>
        )}
      </Box>

      {data?.company ? (
        <Sidebar
          isOpen={isOpen}
          onClose={onToggle}
          company={data.company as Company}
        />
      ) : (
        ""
      )}
    </ChakraProvider>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              {navItem.isValidate === false ? (
                <Box
                  as="a"
                  p={2}
                  href={navItem.href ?? "#"}
                  fontSize={"sm"}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                  display={navItem.display ?? "block"}
                >
                  {navItem.label}
                </Box>
              ) : (
                <Box
                  as="a"
                  p={2}
                  href={navItem.href ?? "#"}
                  fontSize={"sm"}
                  fontWeight={500}
                  color={linkColor}
                  _hover={{
                    textDecoration: "none",
                    color: linkHoverColor,
                  }}
                  display={{ base: "block", md: "none" }}
                >
                  {navItem.label}
                </Box>
              )}
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Box
      as="a"
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("green.50", "green.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "green.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{
        base: "block",
        md: "block",
        lg: "block",
        xl: "none",
        "2xl": "none",
      }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? "#"}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  isValidate?: boolean;
  display?: any;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Re-captcha",
    children: [
      {
        label: "Api Reference",
        subLabel: "Learn more about our api documentation",
        href: "/api-reference",
      },
      {
        label: "Api Key",
        subLabel: "Get your Re-captcha Api key",
        href: "/api-key",
      },
    ],
    isValidate: false,
    display: {
      base: "none",
      md: "none",
      lg: "none",
      xl: "block",
      "2xl": "block",
    },
  },
  {
    label: "Latest IQ Results",
    href: "/latest-iq-results",
    isValidate: false,
    display: {
      base: "none",
      md: "none",
      lg: "none",
      xl: "block",
      "2xl": "block",
    },
  },
  {
    label: "About Recaptcha",
    href: "/about-recaptcha",
    isValidate: true,
  },
  {
    label: "Api Reference",
    href: "/api-reference",
    isValidate: true,
  },
  {
    label: "Api Key",
    href: "/api-key",
    isValidate: true,
  },
  {
    label: "Sign In",
    href: "/sign-in",
    isValidate: true,
  },
  {
    label: "Sign Up",
    href: "/sign-up",
    isValidate: true,
  },
];
