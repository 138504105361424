import { useState, useEffect } from "react";
import { GlobalScoreAction } from "../slice/game/globalScoreSlice";
import { useAppDispatch, useAppSelector } from "./useTypedSelector";


export const useLatestResults = (itemsPerPage: number = 10) => {
    const { data, isLoading } = useAppSelector(
        (state: { globalScore: any }) => state.globalScore,
      );
      const dispatch = useAppDispatch();
      const [currentPage, setCurrentPage] = useState(1);

      useEffect(() => {
        dispatch(GlobalScoreAction());
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
    
      const results = (data?.result || [])
        .slice()
        .sort((a: { id: number }, b: { id: number }) => b.id - a.id);
    
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = results.slice(indexOfFirstItem, indexOfLastItem);
    
      const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
      };

  return { isLoading, paginate, currentItems, results, itemsPerPage };
};