import {
  Box,
  SimpleGrid,
  Heading,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useToast,
} from "@chakra-ui/react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../redux/hooks/useTypedSelector";
import { useForm } from "react-hook-form";
import { AnimatedTypingText } from "../comon/AnimatedTypingText";
import { CustomSelect } from "../comon/CustomSelect";
import countryList from "react-select-country-list";
import { useMemo } from "react";
import { updateAccountAction } from "../../redux/slice/auth/authSlice";
import { useNavigate } from "react-router-dom";
import ContentCard from "../nav/ContentCard";

export const Settings = () => {
  const { data } = useAppSelector((state) => state.auth);
  const countryOptions = useMemo(() => countryList().getData(), []);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      company_id: data.company.user.id,
      company_name: data.company.user.company_name,
      location: data.company.user.location,
    },
  });

  const onSubmitHandler = async (data: any) => {
    try {
      await dispatch(updateAccountAction(data));
      toast({
        title: "Account Updated.",
        description: "Account Updated",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      navigate(`/settings`);
    } catch (error: any) {
      console.log(error.message);
    }
  };
  return (
    <ContentCard>
      <Box borderRadius="15px" px="0px" display="flex">
        <Heading fontSize="32px" mb={5} textAlign={"center"}>
          <AnimatedTypingText text="Settings" speed={0.2} />
        </Heading>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3}>
        <Box
          as={"form"}
          mt={0}
          bg={"#FFF"}
          boxShadow={"lg"}
          borderRadius={5}
          p={5}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Stack spacing={4}>
            <FormControl isRequired>
              <FormLabel>Company Name</FormLabel>
              <Input
                {...register("company_name")}
                id="company_name"
                placeholder="Game's Name"
                isInvalid={!!errors.company_name}
                required
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Country</FormLabel>
              <CustomSelect
                register={register}
                id="location"
                placeholder="Select Country"
                defaultValue={data.company.user.location}
                options={countryOptions}
              />
            </FormControl>
          </Stack>
          <Button
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            colorScheme="blue"
            type="submit"
          >
            {isSubmitting ? <Spinner /> : "Submit"}
          </Button>
        </Box>
        <Box
          mt={0}
          alignItems={"center"}
          textAlign={"center"}
          bg={"#FFF"}
          boxShadow={"lg"}
          borderRadius={5}
          p={9}
        >
          <Heading color={"#666"} fontSize="22px" mb={5} textAlign={"center"}>
            <AnimatedTypingText text="Account Type" speed={0.1} />
          </Heading>
          <Button>Free Plan</Button>
        </Box>
      </SimpleGrid>
    </ContentCard>
  );
};
