import {
  Box,
  Container,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'

export  const DefaultFooter = () =>{
  const currentYear = new Date().getFullYear();

  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}
        // mt={209}
        >
       <Text>{`© ${currentYear} TheLastSpellingBee. All rights reserved`}</Text>
        <Stack direction={'row'} spacing={6}>
          <Text as="a" href='/contact-us'>Contact Us</Text>
        </Stack>
      </Container>
    </Box>
  )
}