import SyntaxHighlighter from "react-syntax-highlighter";
import { monokaiSublime } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Text} from "@chakra-ui/react";


export const JavaApiSampleCode = () => {
const codeSampleGetQuestion = `
    /**
    * Retrieves a Re-captcha question of the specified type and word length.
    *
    * @param {string} type The type of Re-captcha question to retrieve. 
    * type: Must be one of the following: CHARACTERS, NUMBERS, RANDOM, or COMPLEX.
    * @param {integer} wordLength The length of the Re-captcha word to retrieve.
    * @param {string} apiKey The API key obtained from https://www.thelastspellingbee.com/re-captcha/apikey.
    * @return {Promise} A promise that resolves with the Re-captcha question and answer.
    */
    
import java.io.BufferedReader;
import java.io.IOException;
import java.io.InputStreamReader;
import java.net.HttpURLConnection;
import java.net.URL;

public class RecaptchaAPI {

    public static void main(String[] args) {
        String type = "characters";
        int wordLength = 5;
        String apiKey = "fadshjgckxvl536789ewdgv";
        String url = "https://thelastspellingbee-api.onrender.com/api/re-captcha/" + type + "/" + wordLength + "/" + apiKey;

        try {
            URL obj = new URL(url);
            HttpURLConnection con = (HttpURLConnection) obj.openConnection();
            con.setRequestMethod("GET");

            int responseCode = con.getResponseCode();
            System.out.println("GET Response Code :: " + responseCode);

            if (responseCode == HttpURLConnection.HTTP_OK) { // success
                BufferedReader in = new BufferedReader(new InputStreamReader(
                        con.getInputStream()));
                String inputLine;
                StringBuffer response = new StringBuffer();

                while ((inputLine = in.readLine()) != null) {
                    response.append(inputLine);
                }
                in.close();

                // print result
                System.out.println("ReCaptcha Question: " + response.toString());
            } else {
                System.out.println("GET request not worked");
            }
        } catch (IOException e) {
            e.printStackTrace();
        }
    }
}`;
const codeSampleAnswerQuestion = `
/*
In this example, the function submitAnswer takes in the question and answer as parameters, and makes a POST request
 to the specified endpoint with these parameters and the API key. The response is handled and a boolean indicating 
 whether the answer was correct is returned. The JSONObject and HttpURLConnection classes are used to construct the 
 'request and handle the response. Make sure to replace PUT YOUR API KEY HERE with your actual API key.
*/

import java.io.IOException;
import java.io.OutputStream;
import java.net.HttpURLConnection;
import java.net.MalformedURLException;
import java.net.URL;
import java.util.HashMap;
import java.util.Map;

import org.json.JSONObject;

public class ReCaptchaAnswer {
    private final String API_KEY = "PUT YOUR API KEY HERE";
    private final String ENDPOINT = "https://thelastspellingbee-api.onrender.com/api/answer-re-captcha";

    public boolean submitAnswer(String question, String answer) {
        try {
            URL url = new URL(ENDPOINT);
            HttpURLConnection conn = (HttpURLConnection) url.openConnection();
            conn.setRequestMethod("POST");
            conn.setDoOutput(true);
            conn.setRequestProperty("Content-Type", "application/json");

            Map<String, String> data = new HashMap<>();
            data.put("question", question);
            data.put("answer", answer);
            data.put("apikey", API_KEY);

            JSONObject json = new JSONObject(data);
            String jsonString = json.toString();

            OutputStream os = conn.getOutputStream();
            os.write(jsonString.getBytes());
            os.flush();

            if (conn.getResponseCode() == 200) {
                // handle the response and get the boolean indicating whether the answer was correct
                JSONObject response = new JSONObject(conn.getInputStream());
                return response.getBoolean("correct");
            } else {
                // handle the error
                return false;
            }
        } catch (MalformedURLException e) {
            e.printStackTrace();
        } catch (IOException e) {
            e.printStackTrace();
        }
        return false;
    }
}
`;

    return(
       <>
            <Text fontWeight={"bold"} mt={9} mb={1}>Get Question</Text>
            <SyntaxHighlighter language="java" style={monokaiSublime}>
                {codeSampleGetQuestion}
            </SyntaxHighlighter>
            <Text fontWeight={"bold"} mt={9} mb={1}>Answer Question</Text>
            <SyntaxHighlighter language="java" style={monokaiSublime}>
                {codeSampleAnswerQuestion}
            </SyntaxHighlighter>
       </>
    )
}