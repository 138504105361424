import {
  Flex,
  Box,
  Stack,
  Button,
  Text,
  Heading,
  Img,
  Spinner,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/useTypedSelector";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { verifyEmailAction } from "../../redux/slice/auth/authSlice";

export const VerifyEmail = () => {
  const { isError, isLoading, errorMessage } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  let { token } = useParams();
  
  useEffect(() => {
      dispatch(verifyEmailAction(token as string));
    }, [dispatch,token])
  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Box rounded={"lg"} bg={"gray.50"} p={8}>
          <Box as={"form"} mt={0}>
            {isLoading ? (
              <Spinner />
            ) : (
              <>
                {!isError ? (
                  <Flex
                    direction="column"
                    alignItems={"center"}
                    w="100%"
                    background="transparent"
                  >
                    <Img src="/images/check_good.svg" mb={6} h={50} w={50} />
                    <Heading
                      color={"#"}
                      fontSize={25}
                      mb={5}
                      textAlign={"center"}
                    >
                      Your email is now verified {errorMessage}.
                    </Heading>
                    <Text mb={5}>
                      You’re all set. You can now enjoy all the features of
                      TheLastSpellingBee.
                    </Text>
                    <Flex
                      flexDirection="column"
                      justifyContent="left"
                      alignItems="left"
                      maxW="100%"
                      mt="0px"
                    >
                      <Button
                        as="a"
                        href="/dashboard"
                        bg="#2b6cb0"
                        w="100%"
                        h="45"
                        mb="20px"
                        color="white"
                        mt="20px"
                        _hover={{
                          bg: "teal.200",
                        }}
                        _active={{
                          bg: "teal.400",
                        }}
                      >
                        Go to Dashboard
                      </Button>
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    direction="column"
                    w="100%"
                    background="transparent"
                  >
                    <Text color={"red.600"}>
                        {errorMessage}
                    </Text>
                    <Text mt={9}>
                      Failed to verify email. Please try the link again or
                      contact{" "}
                      <Text as={"a"} href="/contact-us" color={"#2b6cb0"}>
                        support
                      </Text>{" "}
                      if the problem persists.
                    </Text>
                  </Flex>
                )}
              </>
            )}
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
};
