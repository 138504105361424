import React from "react";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Box,
} from "@chakra-ui/react";
import { FiInfo } from "react-icons/fi";
import { instructionText } from "../Instructions";

export const GamePlayInstruction = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <Box>
      <Button onClick={onOpen} size="sm" colorScheme="blue" variant="outline">
        <FiInfo style={{ marginRight: "0.5em" }} />
        Instruction
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent p={3}>
          <ModalHeader>Instruction</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>{instructionText}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};
