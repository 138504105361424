import {
  Box,
  FormControl,
  Input,
  Stack,
  Button,
  Text,
} from "@chakra-ui/react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/hooks/useTypedSelector";
import { QuestionsCard } from "./QuestionsCard";
import { useState, useEffect } from "react";
import { GameHeaderCard } from "./GameHeaderCard";
import { useNavigate } from "react-router-dom";
import { IAnswerGame } from "../../../redux/service/schema";
import { answerGameAction } from "../../../redux/slice/game/answerSlice";
import { checkAuth, getCurrentGameScore, validateNumberQuestion, validateQuestion } from "./helper";
import { useGameComplete } from "../../../redux/hooks/useGameComplete";
import { AnimatedBackground } from "./result-chart/AnimatedBackground";
import { useAnswerFeedback } from "../../../redux/hooks/useAnswerFeedback";
import ContentCard from "../../nav/ContentCard";

export const SpellingGamePlay = () => {
  const { playGame } = useAppSelector((state) => state.gamePlay);
  const { answerData } = useAppSelector((state) => state.answerGame);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [gameScore, setGameScore] = useState<number>(0);
  const [answerAlert, setAnswerAlert] = useState<any>();
  const { gameComplete } = useGameComplete(getCurrentGameScore());
  const { renderAnswer } = useAnswerFeedback();
  const [clickSnd] = useState(new Audio("/sounds/click.wav"));

  checkAuth(playGame);
  const [formData, setFormData] = useState({
    token: playGame.user.token,
    question: "",
    answer: "",
  });
  const questions = playGame.question;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    clickSnd.play();
    try {
      const currentQuestion = questions[currentQuestionIndex].question;
      await dispatch(
        answerGameAction({
          ...formData,
          question: validateQuestion(currentQuestion),
        } as IAnswerGame),
      );
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
      setGameScore(answerData?.score + gameScore);
      setAnswerAlert(renderAnswer())

      setShowFeedback(true);
      setTimeout(() => {
        setShowFeedback(false);
      }, 2000);
      setFormData({
        ...formData,
        answer: "",
      });
      
    } catch (error: any) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    const savedGameState = JSON.parse(localStorage.getItem("gameState") || "{}");
    if (savedGameState && savedGameState.currentQuestionIndex < questions.length) {
      setCurrentQuestionIndex(savedGameState.currentQuestionIndex);
      setGameScore(savedGameState.gameScore);
    }
  }, [questions.length]);

  useEffect(() => {
    const gameStateToSave = { currentQuestionIndex, gameScore };
    localStorage.setItem("gameState", JSON.stringify(gameStateToSave));
    
  }, [currentQuestionIndex, gameScore]);

  useEffect(() => {
    
    if (currentQuestionIndex === questions.length) {
      gameComplete();
      navigate('/spelling-result');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentQuestionIndex, questions.length]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value.toUpperCase(),
    });
  };

  const onGameOver = async () => {
    await gameComplete();
    navigate('/spelling-result');
  };

  return (
    <ContentCard>
      <Stack direction="column" align="center" spacing={4} bg={'transparent'}>
        <GameHeaderCard
          currentQuestionIndex={currentQuestionIndex}
          totalQuestions={questions.length}
          score={gameScore}
          gameTime={playGame.gameTime}
          gameName={playGame.gameName}
          onGameOver={onGameOver}
        />
        <Stack p={9}  m="4" borderRadius="sm" bg={'#transparent'} zIndex={1000}>
          {currentQuestionIndex < questions.length && (
            <Box flex={1} mt={[4, 0]}>
              <QuestionsCard
                instructions={playGame.instructions}
                question={validateNumberQuestion(
                  questions[currentQuestionIndex].question,
                )}
              />
            </Box>
          )}
          <Box as={"form"} mt={3} onSubmit={onSubmit}>
            <FormControl id="Answer" w="full">
              <Input
                type="text"
                id="answer"
                onChange={onChange}
                placeholder="Answer"
                value={formData.answer}
                fontSize={34}
                h={50}
                padding={2}
              />
            </FormControl>
            <Button
              type="submit"
              colorScheme={"blue"}
              w="full"
              size={"lg"}
              mt={3}
              // isLoading={isLoading ?? false}
              // loadingText="Submitting"
            >
              Submit
            </Button>
            <Text textAlign="center" mt={0}>
              {showFeedback &&
                answerAlert}
            </Text>
          </Box>
        </Stack>
      </Stack>
      <AnimatedBackground />
    </ContentCard>
  );
};