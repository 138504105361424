import { BrowserRouter as Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {DefaultNav} from "./apps/nav/DefaultNav";
import { ChakraProvider } from "@chakra-ui/react";
import { DefaultFooter } from "./apps/nav/DefaultFooter";
import AppRoutes from "./AppRoutes";
import { useState } from "react";
import initialTheme from './theme/theme'; 

function App() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
   
      <ChakraProvider theme={currentTheme} >
         <Router>
             <DefaultNav setTheme={setCurrentTheme}/>
        <AppRoutes/>
        <DefaultFooter />
        </Router>
      </ChakraProvider>
    
  );
}

export default App;