import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Heading, Stack, Text } from "@chakra-ui/react";

export const NotFound = () => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Box>
      <Box width={"100%"} p={100} bg="#FFFFFF">
        <Stack spacing={4} align="center">
          <Heading color={"#F26B3A"} fontSize={100}>
            404
          </Heading>
          <Heading color={"#F26B3A"}>Page Not Found!</Heading>
          <Text>We can't find the page you're looking for.</Text>

          <Button
            colorScheme="blue"
            h="45"
            mb="20px"
            mt="20px"
            onClick={handleBack}
          >
            Take Me Back
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};
