import { FC } from "react";
import {
  Box,
  Button,
  Heading,
  Text,
} from "@chakra-ui/react";

type QuestionsCardProps = {
  instructions: string;
  question: string | string[];
}

export const QuestionsCard: FC<QuestionsCardProps> = ({
  instructions,
  question,
}) => {
  return (
    <Box>
      <>
        <Box>
          <Heading fontSize={["2xl", "3xl"]} textAlign="center">
            {instructions}
          </Heading>
          <Heading fontSize={["2xl", "3xl"]} textAlign="center">
            <Text as={"span"} mr={3}>
              What is
            </Text>
            {Object.keys(question).map((key, index) => (
                <Button
                  key={index}
                  size="lg"
                  colorScheme="blue"
                  variant="outline"
                  fontSize={["2xl", "3xl"]}
                  borderRadius={3}
                  padding={"19px 3px 19px 3px"}
                  mr={2}
                  border={"1px #F26B3A solid"}
                  cursor={'default'}
                >
                  {question[index]}
                </Button>
            ))}
          </Heading>
        </Box>
      </>
    </Box>
  );
};