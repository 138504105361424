import React, { FC } from "react";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Text,
  useToast,
  Icon,
  Box,
  Spinner,
  Heading,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../redux/hooks/useTypedSelector";
import { ICreateGameGameData } from "../../../redux/service/schema";
import {
  createNewGameAction,
  resetNewGameAction,
} from "../../../redux/slice/game/startNewGameSlice";
import { GameValidationSchema } from "./GameValidationSchema";
import { CreateGameForm } from "../../comon/CreateGameForm";

interface CreateNewGameProps {
  variant?: "outline" | "ghost";
  colorScheme?: "teal" | "";
  companyId: number;
}
export const CreateNewGame: FC<CreateNewGameProps> = ({
  variant = "outline",
  colorScheme = "teal",
  companyId,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting, errors },
  } = useForm<ICreateGameGameData>({
    resolver: yupResolver(GameValidationSchema("auth")),
    defaultValues: {
      company_id: companyId,
    },
  });

  const onSubmitHandler = async (data: any) => {
    try {
      await dispatch(createNewGameAction(data));
      await dispatch(resetNewGameAction());
      toast({
        title: "Game created.",
        description: "We've created your game for you.",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      navigate(`/`);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <>
      <Button onClick={onOpen} colorScheme="blue" size="lg">
        <Icon as={FaPlus} />
        <Text ml={1}>Create New M-IQ</Text>
      </Button>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent p={3}>
          <ModalHeader>
            <Heading fontSize={20}>Create New M-IQ</Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Box
              as={"form"}
              mt={0}
              onSubmit={handleSubmit(onSubmitHandler)}
              data-testid="create-game-form"
            >
              <CreateGameForm
                register={register}
                errors={errors}
                control={control}
                type={"auth"}
              />
              <Button
                fontFamily={"heading"}
                mt={8}
                w={"full"}
                colorScheme="blue"
                size="lg"
                type="submit"
              >
                {isSubmitting ? <Spinner /> : "Submit"}
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
