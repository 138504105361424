import { DisplayLatestResults } from "../spelling/game-play/DisplayLatestResults";


export const LatestResults = () => {
  

  return (
    <DisplayLatestResults paginatePerPage={10} />
  );
};
